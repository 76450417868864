import { SadaEmployeeSelect } from '@components/data-selects/employee-select/styles';
import styled from '@emotion/styled';

export const SadaServiceEmployees = styled.div`
  .ant-space {
    width: 100%;
  }
  ${SadaEmployeeSelect.toString()} {
    flex-grow: 1;
  }
`;
