import { SadaFieldListButtons } from '@components/field-list-buttons/styles';
import { ListItemIn } from '@components/ui/animations';
import { colors } from '@constants/theme/colors.config';
import { radiuses } from '@constants/theme/radius.config';
import { spacings } from '@constants/theme/spacing.config';
import styled from '@emotion/styled';

export const SadaServiceFormQuestion = styled(ListItemIn)`
  background: ${colors.white};
  border-radius: ${radiuses.sm};
  padding: ${spacings.sm} ${spacings.md};
  margin-bottom: ${spacings.md};
  border: 1px solid ${colors.light_gray};
  display: flex;
  align-items: flex-start;
  width: 100%;
  ${SadaFieldListButtons.toString()} {
    margin-left: auto;
  }
`;
