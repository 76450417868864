import Typography from '@components/typography';
import useFs from '@hooks/fs.hook';
import firebase from 'firebase';
import fsOrm from '@services/firestore.service';
import { useState } from 'react';

type Props = {
  filter: String,
  email: String,
};

const LIMIT = 10;

const TotalCases = ({ filter, email }: Props) => {
  const [limit, setLimit] = useState(LIMIT);
  const query = firebase
    .firestore()
    .collection('cases')
    .where(filter, '==', fsOrm[`${filter}s`]?.(email).ref())
    .limit(limit + 1);
  const [cases, loading] = useFs({ query });
  const total = cases?.length <= limit ? cases?.length || 1 - 1 : `${limit}+`;
  return (
    <Typography
      loading={loading}
      onClick={
        cases?.length > limit
          ? (e) => {
              e.stopPropagation();
              setLimit(1000);
            }
          : null
      }
    >
      {total}
    </Typography>
  );
};

export default TotalCases;
