import { SadaNextAppointment } from './styles';
import fsOrm from '@services/firestore.service';
import useFs from '@hooks/fs.hook';
import firebase from 'firebase';
import { Typography } from 'antd';
import { humanTimestampDateTime } from '@utils/date.utils';
import { startOfMinute } from 'date-fns';

type Props = {
  filter: String,
  email: String,
};
const NextAppointment = ({ filter, email }: Props) => {
  const query = firebase
    .firestore()
    .collection('appointments')
    .where(filter, '==', fsOrm[`${filter}s`]?.(email).ref())
    .where('start', '>', startOfMinute(new Date()))
    .orderBy('start', 'asc');
  const [data, loading] = useFs({ query });
  const start = data?.[0]?.start;
  return (
    <SadaNextAppointment>
      <Typography loading={loading}>
        {start ? humanTimestampDateTime(start) : '-'}
      </Typography>
    </SadaNextAppointment>
  );
};

export default NextAppointment;
