import { SadaServiceSplashImage } from './styles';
import type IService from '@types/service.type';
import ContentCard from '@components/cards/content-card';
import KFileUpload from '@components/k-file-upload';
import fsOrm from '@services/firestore.service';

type Props = IService;

const ServiceSplashImage = ({ splash, id }: Props) => {
  const setSplash = async (splash) => {
    await fsOrm.services(id).update({ splash });
  };
  return (
    <SadaServiceSplashImage>
      <ContentCard title="Splash Image" light>
        <KFileUpload
          value={splash}
          onChange={setSplash}
          full
          onDelete={() => {
            setSplash(null);
          }}
        />
      </ContentCard>
    </SadaServiceSplashImage>
  );
};

export default ServiceSplashImage;
