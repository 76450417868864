import { SadaServiceFormStep } from './styles';
import ServiceFormQuestion from './service-form-question';
import type IFormStep from '@types/form-step.type';
import ContentCard from '@components/cards/content-card';
import Typography from '@components/typography';
import KHeading from '@components/k-heading';
import { Plus } from 'react-iconly';
import useModal from '@hooks/modal.hook';
import FieldListButtons from '@components/field-list-buttons';
import type FieldListButtonsProps from '@types/field-list-buttons-props.type';
import { Form, FormInstance } from 'antd';
import { AnimatePresence } from 'framer-motion';

type Props = IFormStep & {
  onEdit: Function,
  listButtonProps: FieldListButtonsProps,
  form: FormInstance,
};

const ServiceFormStep = (props: Props) => {
  const {
    caption,
    description,
    order,
    questions,
    title,
    listButtonProps,
    form,
    type,
  } = props;
  const stepModal = useModal('step');
  const questionModal = useModal('question');
  return (
    <SadaServiceFormStep>
      <ContentCard
        title={`Step ${order + 1} - ${title}`}
        extra={
          <FieldListButtons
            {...listButtonProps}
            onEdit={() => {
              stepModal.open(props);
            }}
          />
        }
      >
        <Typography sm>{description}</Typography>
        <Typography sm sub>
          {caption}
        </Typography>
        {type === 'list' && (
          <>
            <KHeading
              md
              title="Questions"
              icon={<Plus />}
              onAction={() => {
                questionModal.open({
                  step: order,
                  order: questions?.length || 0,
                });
              }}
            />
            <Form.List name={[order, 'questions']}>
              {(fields, { move, remove }) => (
                <AnimatePresence>
                  {fields.map((field) => {
                    const idx = field.name;
                    const question = form.getFieldValue([
                      'steps',
                      order,
                      'questions',
                      idx,
                    ]);
                    return (
                      <ServiceFormQuestion
                        key={question?.id}
                        {...question}
                        order={idx}
                        listButtonProps={{
                          sm: true,
                          onDelete: () => {
                            remove(idx);
                          },
                          onEdit: () => {
                            questionModal.open({
                              step: order,
                              order: idx,
                              ...question,
                            });
                          },
                          onUp:
                            idx > 0
                              ? () => {
                                  move(idx, idx - 1);
                                }
                              : null,
                          onDown:
                            idx < fields.length - 1
                              ? () => {
                                  move(idx, idx + 1);
                                }
                              : null,
                        }}
                        stepOrder={order}
                      />
                    );
                  })}
                </AnimatePresence>
              )}
            </Form.List>
          </>
        )}
      </ContentCard>
    </SadaServiceFormStep>
  );
};

export default ServiceFormStep;
