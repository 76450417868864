import KAvatar from '@components/k-avatar';
import NextAppointment from '@components/next-appointment';
import RemoteStatusTag from '@components/remote-status-tag';
import TotalCases from '@components/total-cases';

const columns = [
  {
    render: KAvatar,
    key: 'photo',
    width: 60,
  },
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Phone',
    dataIndex: 'phone',
    key: 'phone',
  },
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
  },
  {
    title: 'Total Cases',
    render: ({ email }) => <TotalCases filter="applicant" email={email} />,
    key: 'total_cases',
  },
  {
    title: 'Next Appointment',
    render: ({ email }) => <NextAppointment filter="applicant" email={email} />,
    key: 'next_appointment',
  },
  {
    title: 'Status',
    key: 'status',
    render: ({ email }) => (
      <RemoteStatusTag collection="applicants" id={email} />
    ),
  },
];

const config = {
  columns,
};

export default config;
