import ApplicantCard from '@components/cards/applicant-card';
import CaseTable from '@components/case-table';
import KBreadcrumbs from '@components/k-breadcrumbs';
import KHeading from '@components/k-heading';
import useData from '@hooks/data.hook';
import fsOrm from '@services/firestore.service';
import { Col, Row, Space } from 'antd';
import { navigate } from 'hookrouter';
import { SadaEmployee } from './styles';
import firebase from 'firebase';
import MeetCardWrapper from '@components/meet-card-wrapper';

type Props = { id: String };

const Employee = ({ id }: Props) => {
  const [data, loading] = useData('employees', id);
  const onRowClick = (id) => {
    navigate(`/cases/${id}`);
  };
  const query = firebase
    .firestore()
    .collection('cases')
    .where('employee', '==', fsOrm.employees(id).ref());

  return (
    <SadaEmployee>
      <KBreadcrumbs
        items={[
          { label: 'Employees', href: '/employees' },
          { label: data?.name, loading },
        ]}
      />
      <Space direction="vertical" size={24}>
        <Row gutter={40}>
          <Col xs={14}>
            <ApplicantCard loading={loading} applicant={data} />
          </Col>
          <Col sm={10}>
            <MeetCardWrapper employee={id} />
          </Col>
        </Row>
        <Row>
          <Col xs={24}>
            <KHeading title="Employee Cases" />
            <CaseTable query={query} onRowClick={onRowClick} />
          </Col>
        </Row>
      </Space>
    </SadaEmployee>
  );
};

export default Employee;
