import { SadaServiceEmployees } from './styles';
import type IService from '@/types/service.type';
import ContentCard from '@components/cards/content-card';
import { Space } from 'antd';
import KEmpty from '@components/k-empty';
import KIconButton from '@components/k-icon-button';
import { AddUser } from 'react-iconly';
import { useState } from 'react';
import EmployeeSelect from '@components/data-selects/employee-select';
import KBtn from '@components/k-btn';
import fsOrm from '@services/firestore.service';
import KRef from '@components/k-ref';
import NameWithAvatar from '@components/name-with-avatar';
import { UIFlexBox } from '@components/ui/layout';
import { MinusCircleOutlined } from '@ant-design/icons';
import * as R from 'ramda';

type Props = IService;

const ServiceEmployees = ({ employees = [], id }: Props) => {
  const [adding, setAdding] = useState(false);
  const addEmployee = async (email) => {
    await fsOrm
      .services(id)
      .ref()
      .update({ employees: [...employees, fsOrm.employees(email).ref()] });
    setAdding(false);
  };
  const removeEmployee = async (ref) => {
    await fsOrm
      .services(id)
      .ref()
      .update({ employees: R.without([ref])(employees) });
  };
  return (
    <SadaServiceEmployees>
      <ContentCard
        light
        title="Employees"
        extra={
          <KIconButton
            icon={<AddUser />}
            onClick={() => {
              setAdding(true);
            }}
          />
        }
      >
        {employees?.length || adding ? (
          <Space direction="vertical" size="middle">
            {employees.map((ref) => (
              <KRef dataRef={ref} key={ref?.id}>
                {(data, loading) => (
                  <UIFlexBox justifyContent="space-between">
                    <NameWithAvatar {...data} />
                    <KIconButton
                      icon={<MinusCircleOutlined />}
                      sm
                      danger
                      onClick={() => {
                        removeEmployee(ref);
                      }}
                    />
                  </UIFlexBox>
                )}
              </KRef>
            ))}
            {adding && (
              <EmployeeSelect
                autoFocus
                defaultOpen
                disabled={employees.map(R.prop('id'))}
                onSelect={addEmployee}
                dropdownRender={(menu) => (
                  <div>
                    {menu}
                    <KBtn
                      secondary
                      block
                      onClick={() => {
                        setAdding(false);
                      }}
                    >
                      Cancel
                    </KBtn>
                  </div>
                )}
              />
            )}
          </Space>
        ) : (
          <KEmpty />
        )}
      </ContentCard>
    </SadaServiceEmployees>
  );
};

export default ServiceEmployees;
