import { TableColumnType } from 'antd';
import * as R from 'ramda';

export const servicesTableColumns: [TableColumnType] = [
  {
    key: 'title',
    title: 'Title',
    dataIndex: 'title',
  },
  {
    key: 'description',
    title: 'Description',
    dataIndex: 'description',
    ellipsis: true,
  },
  {
    key: 'steps',
    title: 'Total steps',
    render: R.pipe(R.propOr([], 'steps'), R.length),
  },
  {
    key: 'questions',
    title: 'Total questions',
    render: R.pipe(
      R.propOr([], 'steps'),
      R.pluck('questions'),
      R.unnest,
      R.length,
    ),
  },
];
