import KSelect from '@components/k-select';
import { SadaWorkingHoursPicker } from './styles';
import { Select, SelectProps } from 'antd';
import { minutesToTime } from '@utils/date.utils';

type Props = SelectProps & {
  min: Number,
  max: Number,
  step: Number,
  select: Boolean,
  value: Number,
};
const WorkingHoursPicker = ({
  min = 360,
  max = 1050,
  step = 30,
  onChange = () => {},
  value,
  select,
  ...props
}: Props) => {
  let hours;
  try {
    hours = new Array(Math.floor((max - min) / step))
      .fill(1)
      .map((v, idx) => idx * step + min);
  } catch (error) {
    hours = [];
  }
  return (
    <SadaWorkingHoursPicker>
      <KSelect value={value} onChange={onChange} {...props}>
        {hours.map((hour) => (
          <Select.Option key={hour} value={hour}>
            {minutesToTime(hour)}
          </Select.Option>
        ))}
      </KSelect>
    </SadaWorkingHoursPicker>
  );
};

export default WorkingHoursPicker;
