import { Col } from 'antd';
import GlobalSearch from '../global-search';
import UserMenu from '@components/user-menu';
import { SadaHeader, SadaHeaderDesktop, SadaHeaderMobile } from './styles';
import Logo from '@components/menu/logo';
import MobileMenuButton from '@components/mobile-menu-button';
import useModal from '@hooks/modal.hook';
import useAuth from '@hooks/auth.hook';
import useData from '@hooks/data.hook';

const Header = () => {
  const { open } = useModal('profile');
  const [user] = useAuth();
  const [employee] = useData('employees', user?.email);
  const onEdit = () => {
    if (employee) {
      open({ collection: 'employees', ...employee });
    }
  };
  return (
    <SadaHeader align="middle">
      <SadaHeaderDesktop>
        <Col sm={14}>
          <GlobalSearch />
        </Col>
        <Col sm={10}>
          <UserMenu onEdit={onEdit} />
        </Col>
      </SadaHeaderDesktop>
      <SadaHeaderMobile>
        <MobileMenuButton />
        <Logo />
        <UserMenu onEdit={onEdit} />
      </SadaHeaderMobile>
    </SadaHeader>
  );
};

export default Header;
