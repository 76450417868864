import KSelect from '@components/k-select';

type Props = {};

const options = [
  {
    label: 'This week',
    value: 'this-week',
  },
  {
    label: 'Last 7 days',
    value: 'last-7-days',
  },
  {
    label: 'This month',
    value: 'this-month',
  },
  {
    label: 'Last 30 days',
    value: 'last-30-days',
  },
];

const DateRangeFilter = ({ value, onChange }: Props) => (
  <KSelect
    options={options}
    defaultActiveFirstOption
    value={value}
    onChange={onChange}
  />
);

export default DateRangeFilter;
