import ContentCard from '@components/cards/content-card';
import WorkingHours from '@components/working-hours';
import { SadaServiceSchedule } from './styles';
import type IService from '@/types/service.type';
import fsOrm from '@services/firestore.service';

type Props = IService & {};

const ServiceSchedule = ({ schedule, id }: Props) => {
  const save = async (v) => {
    await fsOrm.services(id).update({ schedule: v });
  };
  return (
    <SadaServiceSchedule>
      <ContentCard title="Working Hours" light>
        {schedule && <WorkingHours schedule={schedule} onSave={save} />}
      </ContentCard>
    </SadaServiceSchedule>
  );
};

export default ServiceSchedule;
