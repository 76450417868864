import KBtn from '@components/k-btn';
import Odometer from 'react-odometerjs';
import Typography from '@components/typography';
import { SadaActionItemCard } from './styles';

type Props = {
  count: Number,
  label: String,
  onClick: (String) => void,
};

const ActionItemCard = ({ count, label, onClick = () => {} }: Props) => {
  return (
    <SadaActionItemCard>
      <Typography xxl bold>
        <Odometer value={count}></Odometer>
      </Typography>
      <Typography sub>
        <span style={{ textTransform: 'capitalize' }}>{label}</span>
      </Typography>
      <KBtn onClick={onClick}>Review</KBtn>
    </SadaActionItemCard>
  );
};

export default ActionItemCard;
