import styled from '@emotion/styled';
import { Collapse } from 'antd';

export const SadaWorkingHours = styled(Collapse)`
  .ant-collapse-extra {
    .ant-form-item {
      margin-bottom: 0;
    }
    .ant-form-item-control-input {
      min-height: 22px;
    }
  }
`;
