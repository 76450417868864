import SearchBar from '@components/search-bar';
import { SadaGlobalSearch } from './styles';

const GlobalSearch = () => (
  <SadaGlobalSearch>
    <SearchBar placeholder="Search by case ID, applicant or employee name" />
  </SadaGlobalSearch>
);

export default GlobalSearch;
