import KSelect from '@components/k-select';
import { Input } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import KFormModal from '../k-form-modal';
import { SadaServiceStepFormModal } from './styles';
import { type IFormStep } from '@types/form-step.type';
import useModal from '@hooks/modal.hook';
import { useEffect } from 'react';
import { newId } from '@utils/firebase.utils';

type Props = { onSubmit: () => IFormStep };

const ServiceStepFormModal = ({ onSubmit }: Props) => {
  const [form] = useForm();
  const { data, visible } = useModal('step');
  const { resetFields } = form;
  useEffect(() => {
    if (visible) {
      resetFields();
    }
  }, [visible, resetFields]);
  return (
    <SadaServiceStepFormModal>
      <KFormModal
        name="step"
        modalProps={{
          title: 'New Application Step',
          onOk: async () => {
            form.setFieldsValue({ order: data?.order, id: await newId() });
            form.submit();
          },
        }}
        formProps={{
          form,
          name: ['steps', data?.order],
          initialValues: { type: 'list', ...data },
          fields: [
            {
              name: 'title',
              label: 'Step Title',
              extra: 'Title to show in the step heading',
              rules: [{ required: true }],
            },
            {
              name: 'description',
              label: 'Description',
              extra: 'General step description',
              component: Input.TextArea,
              rules: [{ required: true }],
            },
            {
              name: 'caption',
              label: 'Caption',
              extra: 'Additional text to show in smaller font',
              component: Input.TextArea,
              initialValue: '',
            },
            {
              name: 'type',
              label: 'Type',
              component: KSelect,
              componentProps: {
                options: [
                  { value: 'list', label: 'List' },
                  { value: 'file', label: 'File Upload' },
                ],
              },
              rules: [{ required: true }],
            },
          ],
        }}
      />
    </SadaServiceStepFormModal>
  );
};

export default ServiceStepFormModal;
