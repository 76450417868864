import { colors } from '@constants/theme/colors.config';
import { spacings } from '@constants/theme/spacing.config';
import styled from '@emotion/styled';
import { Space } from 'antd';

export const SadaKFileUploadHover = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  color: ${colors.blue};
`;

export const SadaKFileUpload = styled.div`
  input[type='file'] {
    display: none;
  }
  margin: ${spacings.md} auto;
  position: relative;
  overflow: hidden;
  cursor: pointer;
`;

export const SadaKFileUploadButtons = styled(Space)`
  justify-content: space-evenly;
  > * {
    flex-grow: 1;
  }
`;
