import { SadaCase } from './styles';
import type ICase from '@types/case.type';
import useData from '@hooks/data.hook';
import KBreadcrumbs from '@components/k-breadcrumbs';
import {
  useCollectionData,
  useDocumentData,
} from 'react-firebase-hooks/firestore';
import { Col, Empty, Row, Space } from 'antd';
import KHeading from '@components/k-heading';
import { Paper, Plus, TimeCircle } from 'react-iconly';
import CaseDetailsCard from '@components/cards/case-details-card';
import AppointmentCard from '@components/cards/appointment-card';
import useModal from '@hooks/modal.hook';
import ApplicantCard from '@components/cards/applicant-card';
import EmployeeCard from '@components/cards/employee-card';
import StatusTag from '@components/status-tag';
import DocumentsSection from '@components/documents-section';
import fsOrm from '@services/firestore.service';
import useOrg from '@hooks/org.hook';
import firebase from 'firebase';
import CaseStatusButtons from '@components/case-status-buttons';
import MeetCardWrapper from '@components/meet-card-wrapper';
import KIconButton from '@components/k-icon-button';
import ActivityList from '@components/activity-list';
import CommentsSection from '@components/comments-section';

type Props = {
  id: String,
};

const Case = ({ id }: Props) => {
  const [data, caseLoading]: [ICase] = useData('cases', id);
  const { open: openAnswersModal } = useModal('answers');
  const [service, serviceLoading] = useDocumentData(data?.service);
  const [applicant, applicantLoading] = useDocumentData(data?.applicant);
  const [employee, employeeLoading] = useDocumentData(data?.employee);
  const onDocsChange = async (attachments) => {
    await fsOrm.cases(id).update({ attachments });
  };
  const [org] = useOrg();
  const [appointments = []] = useCollectionData(
    firebase
      .firestore()
      .collection('appointments')
      .where('case', '==', fsOrm.cases(id).ref()),
  );
  const { open: openCaseManagerDialog } = useModal('case-manager');
  const { open: openAppointmentDialog } = useModal('appointment');
  const { open: openCaseStatusDialog } = useModal('case-status');
  const [statuses] = useCollectionData(
    firebase.firestore().collection('cases').doc(id).collection('statuses'),
  );
  return (
    <SadaCase>
      <KBreadcrumbs
        items={[
          { label: 'Case List', href: '/cases' },
          {
            label: `${service?.title} #${data?.id}`,
            loading: caseLoading || serviceLoading,
          },
        ]}
      />
      <Row gutter={40}>
        <Col sm={14}>
          <Space direction="vertical" size={24}>
            <div>
              <KHeading
                title="Case Details"
                extra={
                  data?.formAnswers && (
                    <KIconButton
                      icon={<Paper />}
                      onClick={() => {
                        openAnswersModal({ form: data?.formAnswers });
                      }}
                    />
                  )
                }
              />
              <CaseDetailsCard
                caseData={[data, caseLoading]}
                service={[service, serviceLoading]}
                cover={{ image: org?.splash, title: org?.name }}
              />
            </div>
            <div>
              <KHeading
                title="Appointments"
                icon={<Plus />}
                onAction={() => {
                  openAppointmentDialog({ ...data, service });
                }}
              />
              <Space direction="vertical">
                {appointments?.length ? (
                  appointments.map((appointment) => (
                    <AppointmentCard {...appointment} key={appointment.id} />
                  ))
                ) : (
                  <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                )}
              </Space>
            </div>
            <div>
              <KHeading title="Applicant" />
              <ApplicantCard applicant={applicant} loading={applicantLoading} />
            </div>
            <div>
              <KHeading title="Case Manager" />
              <EmployeeCard
                employee={employee}
                loading={employeeLoading}
                onEdit={() => {
                  openCaseManagerDialog({ employee, caseId: id });
                }}
              />
            </div>
          </Space>
        </Col>
        <Col sm={10}>
          <Space size={40} direction="vertical">
            <div>
              <KHeading
                title="Case Status"
                icon={<TimeCircle />}
                onAction={() => {
                  openCaseStatusDialog({ statuses, id });
                }}
              />
              {data && <StatusTag {...data?.status} large />}
            </div>
            {data?.applicant && (
              <MeetCardWrapper applicant={data.applicant.id} />
            )}
            <CaseStatusButtons {...data} />
            <CommentsSection caseId={id} />
            <DocumentsSection
              docs={data?.attachments}
              onChange={onDocsChange}
            />
            <ActivityList
              query={firebase
                .firestore()
                .collection('activity')
                .where('case', '==', fsOrm.cases(id).ref())}
            />
          </Space>
        </Col>
      </Row>
    </SadaCase>
  );
};

export default Case;
