import KDataTable from '@components/k-data-table';
import KHeading from '@components/k-heading';
import useList from '@hooks/list.hook';
import { Col, Row } from 'antd';
import { navigate } from 'hookrouter';
import { Plus } from 'react-iconly';
import { SadaSettings } from './styles';
import { servicesTableColumns } from './config';

const Settings = () => {
  const [services, loading] = useList('services');

  return (
    <SadaSettings>
      <Row>
        <Col sm={24}>
          <KHeading
            title="Services"
            icon={<Plus />}
            onAction={() => {
              navigate('/settings/service/new');
            }}
          />
          <KDataTable
            config={{
              dataSource: services,
              loading: loading,
              rowKey: 'title',
              columns: servicesTableColumns,
              onRow: ({ id }) => ({
                onClick(v) {
                  v.preventDefault();
                  navigate(`/settings/service/${id}`);
                },
              }),
            }}
          />
        </Col>
      </Row>
    </SadaSettings>
  );
};
export default Settings;
