import KBtn from '@components/k-btn';
import KIconButton from '@components/k-icon-button';
import Typography from '@components/typography';
import { useUpload } from '@hooks/upload.hook';
import { Progress, Image, Popconfirm } from 'antd';
import React, { useRef, useState } from 'react';
import { Edit } from 'react-iconly';
import {
  SadaKFileUpload,
  SadaKFileUploadButtons,
  SadaKFileUploadHover,
} from './styles';

type Props = {
  onChange: Function,
  onDelete: Function,
  value: String,
  full: Boolean,
};

const KFileUpload = ({
  value,
  onChange = () => {},
  onDelete = () => {},
  full,
}: Props) => {
  const inputRef = useRef();
  const [file, setFile] = useState();
  const onSelect = async ({ target }) => {
    setFile(target.files[0]);
  };

  const onSuccess = ({ link }) => {
    onChange(link);
  };

  const { progress, uploading } = useUpload({ file, onSuccess });
  return (
    <SadaKFileUpload>
      {uploading ? (
        <Progress type="circle" percent={progress} width={100} />
      ) : (
        <>
          {value ? (
            <Image src={value} size={100} preview={value} />
          ) : (
            <Typography sub>File missing</Typography>
          )}
          <input
            type="file"
            ref={inputRef}
            onChange={onSelect}
            accept="image/png, .jpeg, .jpg, image/gif, .svg"
          />
          {full ? (
            <SadaKFileUploadButtons>
              <Popconfirm
                title="Delete splash image?"
                onConfirm={onDelete}
                okText="Delete"
                okType="danger"
              >
                <KBtn block secondary danger>
                  Delete
                </KBtn>
              </Popconfirm>
              <KBtn
                block
                secondary
                onClick={() => {
                  if (!uploading) {
                    inputRef.current.click();
                  }
                }}
              >
                Edit
              </KBtn>
            </SadaKFileUploadButtons>
          ) : (
            <SadaKFileUploadHover>
              <KIconButton
                icon={<Edit />}
                onClick={() => {
                  if (!uploading) {
                    inputRef.current.click();
                  }
                }}
              />
            </SadaKFileUploadHover>
          )}
        </>
      )}
    </SadaKFileUpload>
  );
};

export default KFileUpload;
