import ContentCard from '@components/cards/content-card';
import KBtn from '@components/k-btn';
import KInput from '@components/k-input';
import functionsService from '@services/functions.service';
import { Divider, Popconfirm, Space } from 'antd';
import { SadaServiceCalendarForm } from './styles';
import type ISevice from '@types/service.type';
import fsOrm from '@services/firestore.service';
import { useState } from 'react';
import { Delete } from 'react-iconly';
import KIconButton from '@components/k-icon-button';

type Props = ISevice;

const ServiceCalendarForm = ({ calendarId, title, id }: Props) => {
  const [loading, setLoading] = useState(false);
  const createCalendar = async () => {
    setLoading(true);
    const data = await functionsService.createCalendar(
      title,
      'Calendar created',
    );
    await fsOrm.services(id).update({ calendarId: data });
    setLoading(false);
  };

  const removeCalendar = async () => {
    setLoading(true);
    await fsOrm.services(id).update({ calendarId: null });
    setLoading(false);
  };

  return (
    <SadaServiceCalendarForm>
      <ContentCard
        title="Calendar"
        light
        extra={
          calendarId && (
            <Popconfirm title="Detach calendar?" onConfirm={removeCalendar}>
              <KIconButton icon={<Delete />} danger />
            </Popconfirm>
          )
        }
      >
        {calendarId ? (
          <KBtn
            block
            secondary
            href={`https://calendar.google.com/calendar/u/0/r?cid=${calendarId}`}
            target="_blank"
          >
            View Google Calendar
          </KBtn>
        ) : (
          <div>
            <KBtn block onClick={createCalendar} loading={loading ? '1' : ''}>
              New Google Calendar
            </KBtn>
            <Divider>or</Divider>
            <Space direction="vertical">
              <KInput placeholder="Google Calendar ID" />
              <KBtn block outline>
                Attach existing calendar
              </KBtn>
            </Space>
          </div>
        )}
      </ContentCard>
    </SadaServiceCalendarForm>
  );
};

export default ServiceCalendarForm;
