import screens from '@constants/theme/screens.config';
import { spacings } from '@constants/theme/spacing.config';
import styled from '@emotion/styled';

export const SadaActionItems = styled.div`
  ${screens.mobile} {
    .ant-col {
      margin-bottom: ${spacings.md};
    }
  }
`;
