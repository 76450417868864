import { ListItemIn } from '@components/ui/animations';
import { spacings } from '@constants/theme/spacing.config';
import styled from '@emotion/styled';
import { Space } from 'antd';

export const SadaServiceFormStep = styled(ListItemIn)`
  margin-bottom: ${spacings.md};
`;

export const SadaServiceFormStepActions = styled(Space)`
  justify-content: flex-end;
`;
