import { SadaService } from './styles';
import { type ISevice } from '@types/service.type';
import { Col, Row, Space } from 'antd';
import KHeading from '@components/k-heading';
import ServiceForm from '@components/forms/service-form';
import { navigate } from 'hookrouter';
import fsOrm from '@services/firestore.service';
import { newId } from '@utils/firebase.utils';
import useData from '@hooks/data.hook';
import KBreadcrumbs from '@components/k-breadcrumbs';
import ServiceCalendarForm from './service-calendar-form';
import ServiceEmployees from './service-employees';
import ServiceSchedule from './service-schedule';
import { defaultSchedule } from '@components/working-hours/config';
import ServiceSplashImage from './service-splash-image';
import ServiceLink from './service-link';

type Props = ISevice & {};

const Service = ({ id }: Props) => {
  const isNew = id === 'new';
  const createService = async (data) => {
    if (isNew) {
      const id = await newId();
      await fsOrm
        .services()
        .create({ ...data, id, schedule: defaultSchedule }, 'Service created');
    } else {
      await fsOrm.services(id).update(data);
    }
    navigate('/settings');
  };

  const [service, loading] = useData('services', id);
  return (
    <SadaService>
      <KBreadcrumbs
        items={[
          { href: '/settings', label: 'Settings' },
          {
            label: isNew ? 'New Service' : service?.title,
            loading: !isNew && loading,
          },
        ]}
      />
      <Row gutter={40}>
        <Col sm={14}>
          <KHeading title={isNew ? 'New Service' : 'Edit service'} xl />
          {!loading && (
            <ServiceForm onSubmit={createService} service={!isNew && service} />
          )}
        </Col>
        {service && (
          <Col sm={10}>
            <KHeading title=" " xl extra={<ServiceLink {...service} />} />
            <Space size={40} direction="vertical">
              <ServiceCalendarForm {...service} />
              <ServiceEmployees {...service} />
              <ServiceSchedule {...service} />
              <ServiceSplashImage {...service} />
            </Space>
          </Col>
        )}
      </Row>
    </SadaService>
  );
};

export default Service;
