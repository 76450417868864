import { SadaWorkingHours } from './styles';
import { type ISchedule } from '@types/schedule.type';
import * as R from 'ramda';
import { getDay } from '@utils/date.utils';
import { Button, Collapse, Form, Switch } from 'antd';
import { useState } from 'react';
import { UIFlexBox } from '@components/ui/layout';
import WorkingHoursPicker from './working-hours-picker';

type Props = { schedule: ISchedule, onSave: Function };

const layout = {
  labelCol: { xs: 4 },
  wrapperCol: { xs: 17 },
  labelAlign: 'right',
};
const WorkingHours = ({ schedule = {}, onSave = () => {} }: Props) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [fields, setFields] = useState(R.map(R.prop('active'))(schedule));
  return (
    <div>
      <Form
        {...layout}
        form={form}
        initialValues={schedule}
        onFinish={async (v) => {
          setLoading(true);
          await onSave(R.map(R.reject(R.isNil))(v));
          setLoading(false);
        }}
        onFieldsChange={(v, values) => {
          if (v?.[0]?.name?.[1] === 'active') {
            setFields({ ...fields, [v[0]?.name?.[0]]: v[0].value });
          }
        }}
      >
        <SadaWorkingHours>
          {R.pipe(
            R.mapObjIndexed((day, idx) => (
              <Collapse.Panel
                key={idx}
                header={getDay(idx)}
                forceRender
                extra={
                  <div
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                  >
                    <Form.Item name={[idx, 'active']} valuePropName="checked">
                      <Switch />
                    </Form.Item>
                  </div>
                }
              >
                {fields[idx] && (
                  <div>
                    <Form.Item
                      name={[idx, 'start']}
                      label="Start"
                      rules={[{ required: true }]}
                    >
                      <WorkingHoursPicker />
                    </Form.Item>
                    <Form.Item
                      name={[idx, 'end']}
                      label="End"
                      rules={[{ required: true }]}
                    >
                      <WorkingHoursPicker />
                    </Form.Item>
                  </div>
                )}
              </Collapse.Panel>
            )),
            R.values,
          )(schedule)}
        </SadaWorkingHours>
      </Form>
      <UIFlexBox justifyContent="flex-end" my={16}>
        <Button onClick={form.submit} loading={loading}>
          Save
        </Button>
      </UIFlexBox>
    </div>
  );
};

export default WorkingHours;
