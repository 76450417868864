import ContentCard from '@components/cards/content-card';
import KBtn from '@components/k-btn';
import KFileUpload from '@components/k-file-upload';
import KForm from '@components/k-form';
import { UIFlexBox } from '@components/ui/layout';
import useOrg from '@hooks/org.hook';
import { phonePattern } from '@utils/form.utils';
import { Col, message, Row } from 'antd';
import { Form } from 'antd';
import { MaskedInput } from 'antd-mask-input';
import { SadaOrgProfileSettings } from './styles';
import * as R from 'ramda';
import firebase from 'firebase';
import { useState } from 'react';

const OrgProfileSettings = () => {
  const [form] = Form.useForm();
  const [org] = useOrg();
  const [loading, setLoading] = useState(false);
  const onSubmit = async (v) => {
    setLoading(true);
    const values = R.filter(R.identity)(v);
    await firebase.firestore().doc('organization/primary').update(values);
    message.success('Organization Profile updated');
    setLoading(false);
  };
  return (
    <SadaOrgProfileSettings>
      <Row>
        <Col md={14} xs={24}>
          {org && (
            <ContentCard title="Organization Profile Settings" light>
              <KForm
                onFinish={onSubmit}
                form={form}
                fields={[
                  {
                    label: 'Name',
                    fieldKey: 'name',
                    name: 'name',
                    rules: [{ required: true }],
                  },
                  {
                    label: 'Phone',
                    rules: [
                      {
                        pattern: phonePattern,
                        message: 'Invalid Phone',
                      },
                    ],
                    fieldKey: 'phone',
                    name: 'phone',
                    component: MaskedInput,
                    componentProps: {
                      mask: '(111) 111-1111',
                    },
                  },
                  {
                    label: 'Address',
                    fieldKey: 'address',
                    name: 'address',
                  },
                  {
                    label: 'Website',
                    fieldKey: 'website',
                    name: 'website',
                  },

                  {
                    label: 'Logo',
                    fieldKey: 'logo',
                    name: 'logo',
                    component: KFileUpload,
                  },
                  {
                    label: 'Small Logo',
                    fieldKey: 'logo_small',
                    name: 'logo_small',
                    component: KFileUpload,
                  },
                  {
                    label: 'Splash Image',
                    fieldKey: 'splash',
                    name: 'splash',
                    component: KFileUpload,
                  },
                ]}
                initialValues={org}
              />
              <UIFlexBox justifyContent="flex-end">
                <KBtn
                  onClick={() => {
                    form.submit();
                  }}
                  loading={loading}
                  disabled={loading}
                >
                  Save
                </KBtn>
              </UIFlexBox>
            </ContentCard>
          )}
        </Col>
      </Row>
    </SadaOrgProfileSettings>
  );
};

export default OrgProfileSettings;
