import NameWithAvatar from '@components/name-with-avatar';
import NextAppointment from '@components/next-appointment';
import StatusTag from '@components/status-tag';
import TotalCases from '@components/total-cases';

const columns = [
  {
    render: (props) => <NameWithAvatar {...props} />,
    key: 'name',
    title: 'Employee',
  },

  {
    title: 'Phone',
    dataIndex: 'phone',
    key: 'phone',
  },
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
  },
  {
    title: 'Total Cases',
    render: ({ email }) => <TotalCases filter="employee" email={email} />,
    key: 'total_cases',
  },
  {
    title: 'Next Appointment',
    render: ({ email }) => <NextAppointment filter="employee" email={email} />,
    key: 'next_appointment',
  },
  {
    render: ({ status }) => <StatusTag {...status} />,
    key: 'status',
    title: 'Status',
  },
  {
    width: 70,
    key: 'admin',
    render: ({ admin, name }) =>
      admin ? <StatusTag neutral text="Admin" /> : null,
  },
];

const config = {
  columns,
};

export default config;
