import KSelect from '@components/k-select';
import useModal from '@hooks/modal.hook';
import { newId } from '@utils/firebase.utils';
import { minLengthValidator } from '@utils/form.utils';
import { Input } from 'antd';
import Checkbox from 'antd/lib/checkbox/Checkbox';
import { useForm } from 'antd/lib/form/Form';
import { useEffect, useState } from 'react';
import KFormModal from '../k-form-modal';
import { SadaServiceQuestionFormModal } from './styles';

type Props = { onSubmit: () => IFormStep };

const ServiceQuestionFormModal = ({ onSubmit }: Props) => {
  const [form] = useForm();
  // form.
  const { data, visible } = useModal('question');
  const type = data?.type || 'single'
  const [t, setT] = useState(type);
  const singleFields = ['text', 'checkbox'];
  const [showOptions, setShowOptions] = useState(false);
  const { resetFields } = form;
  useEffect(() => {
    if (visible) {
      resetFields();
      setShowOptions(type ? !singleFields.includes(type) : false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible, resetFields, type]);

  useEffect(() => {
    setT(type)
  }, [type])

  return (
    <SadaServiceQuestionFormModal>
      <KFormModal
        name="question"
        modalProps={{
          title: 'New Question',
          onOk: async () => {
            form.setFieldsValue({ order: data?.order, id: await newId() });
            form.submit();
          },
        }}
        formProps={{
          form,
          onFieldsChange: ([{ value, name }]) => {
            if (name?.[0] === 'type') {
              setT(value)
            }
          },
          name: ['steps', data?.step, 'questions', data?.order],
          initialValues: { type: 'single', ...data },
          fields: [
            {
              name: 'text',
              label: 'Question',
              extra: 'Text to show in the question heading',
              rules: [{ required: true }],
            },
            {
              name: 'caption',
              label: 'Caption',
              extra: 'Additional description',
              component: Input.TextArea,
              initialValue: '',
            },
            {
              name: 'required',
              label: 'Required',
              component: Checkbox,
              initialValue: false,
              valuePropName: 'checked',
            },
            {
              name: 'type',
              label: 'Type',
              component: KSelect,
              componentProps: {
                options: [
                  { value: 'single', label: 'Text Field' },
                  { value: 'textarea', label: 'Text Area' },
                  { value: 'phone', label: 'Phone Number' },
                  { value: 'text', label: 'Multiple Text Fields' },
                  { value: 'select', label: 'Select' },
                  { value: 'multiselect', label: 'Multi Select' },
                  { value: 'checkbox', label: 'Checkboxes' },
                  { value: 'checkboxgroup', label: 'Checkbox Group' },
                  { value: 'radio', label: 'Radio' },
                ],
              },
            },
            ...(['phone', 'single', 'textarea'].includes(t) ? [] : showOptions
              ? [
                {
                  list: true,
                  name: 'options',
                  label: 'Options',
                  listLabel: 'Option',
                  rules: [
                    {
                      validator: minLengthValidator(
                        2,
                        'Minimum 2 optioins required',
                      ),
                    },
                  ],
                },
              ]
              : [
                {
                  list: true,
                  name: 'options',
                  label: 'Fields',
                  listLabel: 'Field Label',
                  rules: [
                    {
                      validator: minLengthValidator(
                        1,
                        'Minimum 1 field is required',
                      ),
                    },
                  ],
                },
              ]),
          ],
        }}
      />
    </SadaServiceQuestionFormModal>
  );
};

export default ServiceQuestionFormModal;
