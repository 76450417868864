import KIconButton from '@components/k-icon-button';
import Typography from '@components/typography';
import { message, Space } from 'antd';
import { Paper } from 'react-iconly';
import { SadaServiceLink } from './styles';
import Copy from 'copy-to-clipboard';

type Props = { id: String };

const ServiceLink = ({ id }: Props) => {
  // eslint-disable-next-line no-restricted-globals
  const link = `https://${location.host.replace(
    'admin',
    'applicant',
  )}/apply/${id}`;
  const onCopy = () => {
    Copy(link);
    message.info('Link copied to clipboard');
  };
  return (
    <SadaServiceLink>
      <Space>
        <Typography>
          Application Link: <a href={link}>{link}</a>
        </Typography>
        <KIconButton icon={<Paper set="broken" />} onClick={onCopy} />
      </Space>
    </SadaServiceLink>
  );
};

export default ServiceLink;
