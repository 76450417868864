import { Form, Input, InputNumber } from 'antd';
import { SadaServiceForm } from './styles';
import type IService from '@types/service.type';
import { useState } from 'react';
import KBtn from '@components/k-btn';
import { UIFlexBox } from '@components/ui/layout';
import ServiceFormStep from './service-form-step';
import KHeading from '@components/k-heading';
import ContentCard from '@components/cards/content-card';
import { Plus } from 'react-iconly';
import ServiceStepFormModal from '@components/modals/service-step-form-modal';
import useModal from '@hooks/modal.hook';
import ServiceQuestionFormModal from '@components/modals/service-question-form-modal';
import { AnimatePresence } from 'framer-motion';
import { minLengthValidator } from '@utils/form.utils';
import Typography from '@components/typography';
import * as R from 'ramda';

type Props = { service: IService, onSubmit: (IService) => {} };

const layout = {
  labelCol: { xs: 4 },
  wrapperCol: { xs: 20 },
  labelAlign: 'right',
};

const ServiceForm = ({ service, onSubmit }: Props) => {
  const [form] = Form.useForm();
  form.getFieldsValue();
  const [saving, setSaving] = useState(false);

  const stepModal = useModal('step');

  const onFormFinish = (name, { values, forms }) => {
    if (name !== 'service') {
      const existing = R.pipe(
        R.pathOr({}, name),
        R.pick(['id', 'questions']),
      )(form.getFieldsValue());
      forms.service.setFields([
        {
          name,
          value: { ...values, ...existing },
        },
      ]);
      stepModal.close();
      forms[name.join(',')].resetFields();
    } else {
      setSaving(true);
      onSubmit(values);
    }
  };
  return (
    <SadaServiceForm>
      <Form.Provider onFormFinish={onFormFinish}>
        <Form form={form} {...layout} name="service" initialValues={service}>
          <ContentCard title="Information" light>
            <Form.Item
              name="title"
              label="Name"
              extra="Title of the provided service"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="description"
              label="Description"
              extra="Description shown on the application form"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="duration"
              label="Duration"
              extra="Default meeting duration in minutes"
              rules={[{ required: true }]}
              initialValue={30}
            >
              <InputNumber min={10} />
            </Form.Item>
          </ContentCard>
          <KHeading
            title="Application steps"
            icon={<Plus />}
            onAction={() => {
              stepModal.open({
                order: form.getFieldValue('steps')?.length || 0,
              });
            }}
          />
          <Form.List
            name="steps"
            rules={[
              {
                validator: minLengthValidator(1, 'Minimum 1 step is required'),
              },
            ]}
          >
            {(steps, { move, remove }, { errors }) => {
              return (
                <AnimatePresence>
                  {steps.map((step) => {
                    const value = form.getFieldValue(['steps', step.name]);
                    return (
                      <ServiceFormStep
                        form={form}
                        {...value}
                        key={value?.id}
                        order={step.name}
                        listButtonProps={{
                          onUp:
                            step.name > 0
                              ? () => {
                                  move(step.name, step.name - 1);
                                }
                              : null,
                          onDown:
                            step.name < steps.length - 1
                              ? () => {
                                  move(step.name, step.name + 1);
                                }
                              : null,
                          onDelete: () => {
                            remove(step.name);
                          },
                        }}
                      />
                    );
                  })}
                  {!!errors?.length && <Typography danger>{errors}</Typography>}
                </AnimatePresence>
              );
            }}
          </Form.List>
        </Form>
        <ServiceStepFormModal />
        <ServiceQuestionFormModal />
      </Form.Provider>
      <UIFlexBox justifyContent="flex-end">
        <KBtn onClick={form.submit} loading={saving}>
          {service ? 'Save' : 'Submit'}
        </KBtn>
      </UIFlexBox>
    </SadaServiceForm>
  );
};

export default ServiceForm;
