import UserCard from '@components/cards/user-card';
import EmployeeWorkload from '@components/employee-workload';
import HideMobile from '@components/hide-mobile';
import KHeading from '@components/k-heading';
import ShowMobile from '@components/show-mobile';
import useAuth from '@hooks/auth.hook';
import { Col, Row, Space } from 'antd';
import ActionItems from './action-items';
import { SadaDashboard } from './styles';

const Dashboard = () => {
  const [user] = useAuth();
  return (
    <SadaDashboard>
      <ShowMobile>
        <UserCard user={user} />
      </ShowMobile>
      <Row gutter={24}>
        <Col sm={14} xs={24}>
          <Space direction="vertical" size={32}>
            <ActionItems />
            <EmployeeWorkload />
          </Space>
        </Col>
        <Col sm={10} xs={24}>
          <HideMobile>
            <KHeading />
            <UserCard user={user} />
          </HideMobile>
        </Col>
      </Row>
    </SadaDashboard>
  );
};

export default Dashboard;
