import { SadaServiceFormQuestion } from './styles';
import type IFormQuestion from '@types/form-question.type';
import Typography from '@components/typography';
import type FieldListButtonsProps from '@types/field-list-buttons-props.type';
import FieldListButtons from '@components/field-list-buttons';

type Props = IFormQuestion & { listButtonProps: FieldListButtonsProps };

const ServiceFormQuestion = ({
  caption,
  options,
  order,
  required_answer,
  sub_questions,
  text,
  type,
  listButtonProps,
  required,
}: Props) => {
  return (
    <SadaServiceFormQuestion>
      <Typography>{order + 1}.</Typography>
      <div>
        <Typography>
          {text}
          {required ? '*' : ''}{' '}
        </Typography>
        <Typography sub>{type}</Typography>
        {options && <Typography>Answers: {options.join(', ')}</Typography>}
      </div>
      <FieldListButtons {...listButtonProps} />
    </SadaServiceFormQuestion>
  );
};

export default ServiceFormQuestion;
