import { radiuses } from '@constants/theme/radius.config';
import { shadows } from '@constants/theme/shadows.config';
import { spacings } from '@constants/theme/spacing.config';
import styled from '@emotion/styled';
import { UICenterBox } from '@components/ui/layout';
import screens from '@constants/theme/screens.config';
import { SadaBtn } from '@components/k-btn/styles';
import { SadaTypography } from '@components/typography/styles';

export const SadaActionItemCard = styled(UICenterBox)`
  padding: ${spacings.lg} ${spacings.sm};
  border-radius: ${radiuses.lg};
  box-shadow: ${shadows.sm};
  min-width: 200px;
  .label {
    text-transform: capitalize;
  }
  ${SadaBtn.toString()} {
    margin: ${spacings.md} 0 0;
  }
  flex-direction: column;
  ${screens.mobile} {
    flex-direction: row;
    ${SadaBtn.toString()} {
      margin: 0 0 0 auto;
    }
    align-items: center;
    padding: ${spacings.lg};
    ${SadaTypography.toString()} {
      &:first-child {
        margin-right: ${spacings.xs};
      }
    }
  }
`;
