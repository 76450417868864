import KDataTable from '@components/k-data-table';
import NameWithAvatar from '@components/name-with-avatar';
import useList from '@hooks/list.hook';
import { SadaEmployeeWorkload } from './styles';
import firebase from 'firebase';
import KHeading from '@components/k-heading';
import DateRangeFilter from '@components/date-range-filter';
import { useState } from 'react';
import { dateFilteredQuery } from '@utils/firebase.utils';
import { useCollectionDataOnce } from 'react-firebase-hooks/firestore';
import * as R from 'ramda';

const columns = [
  {
    title: 'Name',
    render: (row) => <NameWithAvatar {...row} />,
    key: 'name',
  },
  {
    title: 'New Cases',
    // render: ({ email }) => <Typography>{newCases[email]}</Typography>,
    dataIndex: 'new',
    key: 'cases',
  },
  {
    title: 'Completed Cases',
    dataIndex: 'completed',
    // render: ({ email }) => <Typography>{completedCases[email]}</Typography>,
    key: 'completed',
  },
];
const EmployeeWorkload = () => {
  const [employees = [], loading] = useList('employees', {
    where: ['status.status', '==', 'active'],
  });
  const [filter, setFilter] = useState('this-week');
  const { start, end } = dateFilteredQuery(filter);
  const [cases = [], cloading] = useCollectionDataOnce(
    firebase
      .firestore()
      .collection('cases')
      .where('created', '>', start)
      .where('created', '<', end),
  );
  const newCases = R.pipe(
    R.groupBy(R.path(['employee', 'id'])),
    R.map(R.length),
  )(cases);
  const [completed = [], ccloading] = useCollectionDataOnce(
    firebase
      .firestore()
      .collection('cases')
      .where('status.status', '==', 'completed')
      .where('created', '>', start)
      .where('created', '<', end),
  );
  const completedCases = R.pipe(
    R.groupBy(R.path(['employee', 'id'])),
    R.map(R.length),
  )(completed);
  const dataSource = employees.map(({ email, ...row }) => {
    return {
      ...row,
      email,
      new: newCases[email] || 0,
      completed: completedCases[email] || 0,
    };
  });
  return (
    <SadaEmployeeWorkload>
      <KHeading
        title="Employee workload"
        extra={<DateRangeFilter value={filter} onChange={setFilter} />}
      />
      <KDataTable
        config={{
          dataSource,
          columns,
          loading: loading || cloading || ccloading,
          pagination: false,
          rowKey: 'email',
        }}
      />
    </SadaEmployeeWorkload>
  );
};

export default EmployeeWorkload;
