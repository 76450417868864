import { type IMenuItem } from '@/types/menu.type';
import Employees from '@/apps/admin/components/employees';

import {
  Category,
  TwoUsers,
  Work,
  People,
  // Document,
  Calendar,
  Password,
} from 'react-iconly';

import Applicants from '@/apps/admin/components/applicants';
// import Appointments from '@components/admin/appointments';
import Cases from '@/apps/admin/components/cases';
import Dashboard from '@/apps/admin/components/dashboard';
// import FeedbackForms from '@/apps/admin/components/feedback-forms';
import Schedule from '@/apps/admin/components/schedule';
import Settings from '../components/settings';
import Service from '../components/settings/service';
import Applicant from '../components/applicants/applicant';
import Case from '../components/cases/case';
import Employee from '../components/employees/employee';
import OrgProfileSettings from '../components/org-profile-settings';

const routesConfig: IMenuItem = {
  '/': {
    component: Dashboard,
    label: 'Dashboard',
    path: '/',
    icon: Category,
  },
  // '/appointments': {
  //   component: Appointments,
  //   label: 'Appointments',
  //   path: '/appointments',
  //   icon: Work,
  // },
  '/cases': {
    component: Cases,
    label: 'Cases',
    path: '/cases',
    icon: Work,
  },
  '/cases/:id': {
    component: Case,
    label: 'Case Details',
    path: '/cases',
    hideMenu: true,
    match: /\/cases\/(?<id>\w+)/,
  },
  '/applicants': {
    component: Applicants,
    label: 'Applicants',
    path: '/applicants',
    icon: TwoUsers,
  },
  '/applicants/:id': {
    component: Applicant,
    label: 'Applicant Details',
    path: '/applicants',
    hideMenu: true,
    match: /\/applicants\/(?<id>\w+)/,
  },
  '/employees': {
    component: Employees,
    label: 'Employees',
    path: '/employees',
    icon: People,
  },
  '/employees/:id': {
    component: Employee,
    label: 'Employee Details',
    path: '/employees',
    hideMenu: true,
    match: /\/employees\/(?<id>\w+)/,
  },
  '/schedule': {
    component: Schedule,
    label: 'Schedule',
    path: '/schedule',
    icon: Calendar,
    full: true,
  },
  // '/form-setup': {
  //   component: FormSetup,
  //   label: 'Form Setup',
  //   path: '/form-setup',
  //   icon: FormSetupIcon,
  // },
  // '/forms': {
  //   component: FeedbackForms,
  //   label: 'Feedback Forms',
  //   path: '/forms',
  //   icon: Document,
  // },
  '/settings': {
    component: Settings,
    label: 'App Settings',
    path: '/settings',
    icon: Password,
    divider: true,
  },
  '/settings/service/:id': {
    component: Service,
    label: 'App Settings',
    path: '/settings',
    hideMenu: true,
    match: /\/settings\/service\/(?<id>\w+)/,
  },
  '/settings/org': {
    component: OrgProfileSettings,
    label: 'App Settings',
    path: '/settings/org',
    hideMenu: true,
  },
};

export default routesConfig;
