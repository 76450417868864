import ActionItemCard from '@components/cards/action-item-card';
import KHeading from '@components/k-heading';
import { Col, Row } from 'antd';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import { SadaActionItems } from './styles';
import firebase from 'firebase';
import { endOfWeek, startOfWeek } from 'date-fns';
import { countCasesByStatus } from '@utils/case.utils';
import { navigate } from 'hookrouter';

type Props = {};

const ActionItems = (props: Props) => {
  const [cases] = useCollectionData(
    firebase
      .firestore()
      .collection('cases')
      .where('created', '>', startOfWeek(new Date()))
      .where('created', '<', endOfWeek(new Date())),
  );
  const count = cases ? countCasesByStatus(cases) : {};
  return (
    <SadaActionItems>
      <KHeading title="Weekly Action Items" />
      <Row gutter={20}>
        {['pending', 'active', 'cancelled'].map((v) => (
          <Col sm={8} xs={24} key={v}>
            <ActionItemCard
              loading={true}
              count={count?.[v] || 0}
              label={`${v} cases`}
              onClick={() => {
                navigate(`/cases`, false, { tab: v });
              }}
            />
          </Col>
        ))}
      </Row>
    </SadaActionItems>
  );
};

export default ActionItems;
